<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <Table :tableBody="tableBody" :tableHeader="tableHeader">
        <div style="margin-bottom: 4rem!important;">
          <select v-model="type" class="form-select float-start" style="width: 200px;" @change="getList">
            <option v-for="(item, key) in retailerMap" :key="key" :value="key">{{item}}</option>
          </select>
          <router-link class="btn btn-primary float-end" style="width: 147px;" to="/notification/new">New Notification</router-link>
        </div>
    </Table>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Notification',
  mounted() {
      this.getList()
  },
  data() {
    return {
      tableHeader: ["Retailer", "Status", "Product URL", "Notification", "Action"],
      tableBody: [],
      breadcrumbs: [{ name: "Notification" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      type: 0,
      status: "",
      retailerMap: {0: "-- SELECT --", 1: "Amazon", 2: "Best Buy", 3: "Target"}
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      this.showModal = false;
      axios.delete("/notification/" + this.currentId).then(() => {
          this.getList();
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    },
    getList() {
      axios.get("/notification?type=" + this.type).then(response => {
          var openModal = this.openModal;
          this.tableBody = response.data.map(item => {
            return {
              retailer: this.retailerMap[item.type],
              status: item.status ? { type: "status", class: "badge bg-success", name: "Active" } : { type: "status", class: "badge bg-danger", name: "Inactive" },
              productUrl: item.productId,
              notification: item.notification ? { type: "status", class: "badge bg-success", name: "On" } : { type: "status", class: "badge bg-danger", name: "Off" },
              action: [{ type: "link", link: "/notification/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" },
                    { type: "button", click: function() { openModal(item.id) }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" }]
            }
          })
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    }
  }
}
</script>
